
.page-actions{
  display: flex;
  justify-content: space-between;
}

#event-list-table{
    background-color: #efefef;
    padding: 30px;
 } 
 
 button {
    padding: 8px 12px;
    margin-right: 10px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  button:last-child {
    background-color: #5701AE;
  }
  
  button:last-child:hover {
    background-color: #240246;
  }
  