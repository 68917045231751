table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 18px;
    text-align: left;
  }
  
  thead {
    background-color: #d8d5d5;
  }
  
  th, td {
    padding: 12px 15px;
    border-bottom: 1px solid #ddd;
  }
  
  tr:nth-of-type(even) {
    background-color: #f9f9f9;
  }
  
  tr:hover {
    background-color: #f1f1f1;
  }
  
  button {
    padding: 8px 12px;
    margin-right: 10px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  button:last-child {
    background-color: #dc3545;
  }
  
  button:last-child:hover {
    background-color: #c82333;
  }
  