/* .row
    {   
        padding: 40px;
        justify-content: space-between;
        display: flex;
        flex-direction: row;
        margin-right: 100px;
    } */

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-left: 100px;
}

.backbutton {
  font-size: 30px;
  background-color: transparent;
  color: white;
  cursor: pointer;
}

.menuItem {
  color: white;
  padding: 10px;
  cursor: pointer;
}

.seymour1 {
  color: white;
  text-align: center;
  margin: 20px;
  font-size: 18px;
  font-weight: bold;
  margin-left: 55px;
  margin-right: 100px;
}
.icon {
  padding: 22px;
}

.event__body {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  place-items: center;
}

.header {
  width: 90vw;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  color: white;
  margin-bottom: 2rem;
}
.backbutton {
  font-size: 30px;
  background-color: transparent;
  color: white;
}

/* .row  {   
      width: 90vw;
        justify-content: center;
        display: flex;
        place-items: center;
        padding: 0 1rem;
    } */

.registerButton3 {
  display: block;
  width: 100%;
  padding: 1rem;
  margin-top: 1rem;
  border-radius: 8px;
}
.registerButton3:hover {
  scale: 0.9;
  transition: ease;
  transition-duration: 300ms;
}

input {
  padding: 0.5rem;
}

.menu {
  position: absolute;
  height: 100px;
  width: 80px;
  top: 44px;
  right: 37px;
  background-color: white;
  padding: 10px;
  border: 1px solid grey;
  border-radius: 5px;
  background-color: #14012c;
}
