.seymourText {
  color: white;
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0px;
  margin-top: 80px;
}
.closedCaption {
  margin-top: 10px;
  color: white;
  font-size: 18px;
  text-align: center;
  font-weight: 700;
  padding-left: 120px;
  padding-right: 120px;
}

.closedCaption2 {
  margin-top: 50px;
  color: white;
  font-size: 18px;
  text-align: center;
  font-weight: 700;
  padding-left: 120px;
  padding-right: 120px;
  margin-bottom: 20px;
}

.loginButton2 {
  width: 113px;
  color: white;
  border-bottom: 1px solid white;
  margin-bottom: 100px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.registerButton2 {
  width: 100px;
  height: 30px;
  background-color: #5701ae;
  color: white;
  border: 1px solid white;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 300px;
  height: 40px;
  position: absolute;
  bottom: 10px;
  cursor: pointer;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.g-sign-btn {
  background-color: white !important;
}

svg {
  width: 25px;
  height: auto;
}
