.name{
    color: white;
}
.columnAdmin{
    margin-left: 13px;
}
h3{
    margin: 0;

}
.rowAdmin{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 20px;
}

.proPlan{
    margin-top: 23px;
    height: 25px;
    width: 150px;
    background-color: #5701AE;
    border-radius: 15px;
    font-size: 15px;
    text-align: center;
    color: white;
}
.proPlantext{
    margin-top: 3px;
}
.hoursLeft{
    color: white;
}
