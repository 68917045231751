.registerButton5{
    display: block;
    width: 100%;
    padding: 1rem;
    margin-top: 1rem;
    border-radius: 8px;
 }
 .registerButton5:hover{
     scale: 0.9;
     transition: ease;
     transition-duration: 300ms;
 }

.inputDevice{
    display: flex;
    justify-content: flex-start;
}


.dropdownContainer{
    display: flex;
    justify-content: center;
    margin-top: 20px;

}
.dropdown {
    font-size: 16px;
    width: 350px;
    display: flex;
    height: 30px;
    justify-content: center;
    background-color: grey;
    color: white;
    border: 1px solid white;
    border-radius: 5px;
    padding: 5px;
}

.sound_check{
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
   place-items: center;
}