.form-group{
    padding-top: 30px;
    padding-left: 22%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.input-field{
    margin-top: 5px;
    height: 30px;
    padding: 8px;
    color: #fff;
    background-color: #ccc;
}

.error-input {
    border: red solid 1px;
}

.text-field{
    margin-top: 10px;
    padding: 15px;
    background-color: #4a4759;
    color: #fff;
    border-radius: 10px;

}


.form-action{
    display: flex; justify-content: space-between; width: 80%; margin-top: 20px;
}

.field-group{
    margin-bottom: 10px;
    line-height: 30px;
    display: flex;
    flex-direction: column;
    /* width: 550px; */
}