body {
  background-color: #14012c;
}

.seymourText {
  color: white;
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0px;
  margin-top: 80px;
}

.closedCaption {
  margin-top: 10px;
  color: white;
  font-size: 18px;
  text-align: center;
  font-weight: 700;
  padding-left: 120px;
  padding-right: 120px;
}

.closedCaption2 {
  margin-top: 50px;
  color: white;
  font-size: 18px;
  text-align: center;
  font-weight: 700;
  padding-left: 120px;
  padding-right: 120px;
  margin-bottom: 20px;
}

form {
  margin-left: 40px;
  margin-right: 40px;
}

/* input[type="text"],
input[type="email"],
input[type="password"] {
    background-color: #4A4759;
    width: 300px;
    height: 30px;
    border-radius: 10px;
    border: 1px solid #6A6680;
    margin-bottom: 12px;
} */

p {
  color: white;
  margin: 4px;
}
/* .loginButton
{
    width: 41px;
    color: white;
    border-bottom: 1px solid white;
    margin-top: 10px;
    margin-bottom: 100px;
} */

.magicLink {
  color: white;
}

.loginButton:hover {
  cursor: pointer;
}
/* .registerButton{
    width: 100px;
    height: 30px;
    background-color: #5701AE;
    color: white;
    border: 1px solid white;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 300px;
    height: 40px;
    position: absolute;
    bottom: 0;
    cursor: pointer;
} */
