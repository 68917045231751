.step-indicator {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100px;
  }
  
  .dot {
    width: 10px;
    height: 10px;
    background-color: grey;
    border-radius: 50%;
  }
  
  .line {
    flex: 1;
    height: 2px;
    background-color: grey;
  }
  
  .dot.active {
    background-color: #5701AE;
  }
  
  .line.active {
    background-color: #5701AE; 
  }
  